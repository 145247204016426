import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Referralimage from "../assets/Mask group.png";
import Coursesimage from "../assets/Mask group (1).png";
import Surgicalimage from "../assets/Mask group (2).png";

const Home = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"));

  // useEffect(() => {
  //  if (user?.token === undefined) { 
  //     // alert("You're not logged in");
  //     // navigate("/login");
  //   }
  // }, []);

  return (
    <>
      <div className="container mx-auto overflow-x-hidden mb-5 font-pop p-5 lg:px-16">
        {
          user?.token !== undefined &&
          <div>
          <p className="md:text-2xl text-lg text-[#415da1]  capitalize">
              Welcome, <span className="font-semibold">{user?.title +" " + user?.first_name + " " + user?.last_name}</span>
              {/* {user?.first_name + " " + user?.last_name} */}
            </p>
          </div> 
        }
      
        <div className="mt-12 bg-[#E8EEFF] shadow-sm rounded-md p-6 flex flex-col md:flex-row gap-10 gap-y-5 items-center">
          <div className="basis-[60%]">
            <p className="text-xl text-[#415DA1] font-medium">
              Courses
            </p>
            <p className="mt-3">
            Upgrade your dental practice with our advanced courses. From restorative dentistry to implantology and orthodontics, our hands-on training, led by experienced instructors, ensures you stay at the forefront of the field. Elevate your skills and earn valuable continuing education credits, catering to both seasoned practitioners and recent graduates.
            </p>
            <button
              className="flex items-center px-4 gap-2 py-1 bg-[#415DA1] my-5 text-white rounded-md shadow-sm "
              onClick={() => navigate("/events")}
            >
              Courses
              <svg
                className="w-4 h-4 text-white "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
          <div className="basis-[40%]">
            <img src={Coursesimage} alt="Courses" />
          </div>
        </div>

        <div className="mt-7 bg-[#E8EEFF] shadow-sm rounded-md p-6 flex flex-col md:flex-row gap-10 gap-y-5 items-center">
          <div className="basis-[40%]">
            <img src={Referralimage} alt="Referrals" />
          </div>
          <div className="basis-[60%]">
            <p className="text-xl text-[#415DA1] font-medium">
              Referrals
            </p>
            <p className="mt-3">
            Refer a friend or family member to our dental practice and share the gift of a healthy, radiant smile. As a valued patient, your trust means the world to us. When you refer someone to our experienced team, you're not only endorsing exceptional dental care but also contributing to the well-being of those you care about. Thank you for spreading the joy of optimal oral health!
            </p>
            <button
              className="flex items-center px-4 gap-2 py-1 bg-[#415DA1] my-5 text-white rounded-md shadow-sm "
              onClick={() => navigate("/referrals")}
            >
              Referrals
              <svg
                className="w-4 h-4 text-white "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>



        <div className="mt-12 bg-[#E8EEFF] shadow-sm rounded-md p-6 flex flex-col md:flex-row gap-10 gap-y-5 items-center">
          <div className="basis-[40%]">
            <img src={Surgicalimage} alt="Surgical Guide" />
          </div>
          <div className="basis-[60%]">
            <p className="text-xl text-[#415DA1] font-medium">
              Surgical Guides
            </p>
            <p className="mt-3">
            Experience precision and efficiency in dental implant procedures with our state-of-the-art Dental Surgical Guide services. Our custom-designed surgical guides ensure accuracy and streamline the implant placement process, optimizing outcomes for both patients and practitioners. Explore the benefits of our Dental Surgical Guides, where precision meets innovation for superior implantology outcomes.
            </p>
            <button
              className="flex items-center px-4 gap-2 py-1 bg-[#415DA1] my-5 text-white rounded-md shadow-sm "
              onClick={() => navigate("/referrals")}
            >
              Surgical Guide
              <svg
                className="w-4 h-4 text-white "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
