import React, { useEffect, useState } from "react";
import PatientForm from "../components/PatientForm";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { tableColumns } from "../Utils";
import StatusFilterButtonAndPopup from "../components/StatusFilterButtonAndPopup";
import { toast } from "react-toastify";


// const token = user?.token
// const headers = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${token}`,
//   'ngrok-skip-browser-warning': 'skip-browser-warning'
// };

// const samplePatientData = [
//   {
//     patient_id: 1,
//     patient_name: "MB",
//     dentist_name: "Nikolaos Nanouris",
//     practice_name: "My Dentist Faversham",
//     surgical_guide_return_date: "12/11/2023 2:32:00 PM",
//     implant_site: "UL5 (UL4)",
//     implant_system: "Neodent GM",
//     implant_diameter: "3.50",
//     implant_length: "10.00",
//     patient_status: "Awaiting Planning"

//   },
//   {
//     patient_id: 2,
//     patient_name: "Mrs Brenda Nichol",
//     dentist_name: "Gautham Pai",
//     practice_name: "Shildon Smile Care",
//     surgical_guide_return_date: "",
//     implant_site: "UL3",
//     implant_system: "",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "Awaiting Planning"

//   },
//   {
//     patient_id: 3,
//     patient_name: "Janeen Emmens",
//     dentist_name: "Aditya",
//     practice_name: "Rosebrough Dental Practice",
//     surgical_guide_return_date: "",
//     implant_site: "UL4",
//     implant_system: "",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 4,
//     patient_name: "Patricia Renwick",
//     dentist_name: "Aditya",
//     practice_name: "Rosebrough Dental Practice",
//     surgical_guide_return_date: "",
//     implant_site: "UR1",
//     implant_system: "",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 5,
//     patient_name: "Victoria Clem	",
//     dentist_name: "Aditya",
//     practice_name: "Rosebrough Dental Practice",
//     surgical_guide_return_date: "",
//     implant_site: "",
//     implant_system: "",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 6,
//     patient_name: "Raymond Goldsmith",
//     dentist_name: "Thomas Conroy",
//     practice_name: "Cramlington Dental Centre",
//     surgical_guide_return_date: "",
//     implant_site: "UL1, UR1, UR2, UR3",
//     implant_system: "Prama",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 7,
//     patient_name: "mic",
//     dentist_name: "Aditya",
//     practice_name: "Rosebrough Dental Practice",
//     surgical_guide_return_date: "",
//     implant_site: "",
//     implant_system: "",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 8,
//     patient_name: "Paul Young",
//     dentist_name: "Anil Kumar Mascal Gangadharaiah",
//     practice_name: "Genix Healthcare Dental Clinic",
//     surgical_guide_return_date: "",
//     implant_site: "UR1",
//     implant_system: "Sweden and Martina Prama",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "Awaiting Planning"

//   },
//   {
//     patient_id: 9,
//     patient_name: "Peter Brown",
//     dentist_name: "rajan",
//     practice_name: "pelton lane ends dental practice",
//     surgical_guide_return_date: "",
//     implant_site: "ur1",
//     implant_system: "sweden and martina",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "More Info Requested"

//   },
//   {
//     patient_id: 10,
//     patient_name: "Helen Telford",
//     dentist_name: "Anil Kumar Mascal Gangadharaiah",
//     practice_name: "Genix Healthcare Dental Clinic",
//     surgical_guide_return_date: "",
//     implant_site: "LR7, LR6 or LR7, LR5",
//     implant_system: "Sweden and Martina Prama",
//     implant_diameter: "",
//     implant_length: "",
//     patient_status: "Approve Plan"

//   },
// ]

// const TableRow = ({ patient, index, updateArray }) => {
//   const [showActionMenu, setShowActionMenu] = useState(false)
//   const navigate = useNavigate()
//   const actionMenuRef = useRef(null)
//   return (
//     <tr
//       className={`text-center text-xs ${index % 2 === 0 && "bg-gray-100"} `}
//       key={patient?.patient_code}
//     >
//       <td className="p-2 border border-gray-400">
//         <input
//           type="checkbox"
//           className="cursor-pointer"
//           onChange={(e) => {
//             if (e?.target?.checked === true) {
//               console.log("selected");
//               updateArray((prevSelected) => [...prevSelected, patient]);
//             } else {
//               console.log("unselected");
//               updateArray((prevSelected) =>
//                 prevSelected.filter(
//                   (selectedItem) =>
//                     selectedItem?.patient_id !== patient?.patient_id
//                 )
//               );
//             }
//           }}
//         />
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.first_name + " " + patient?.last_name}
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.assigned_doctor_first_name +
//           " " +
//           patient?.assigned_doctor_last_name}
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.assigned_doctor_practice_name}
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.created_by_first_name + " " + patient?.created_by_last_name}
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.created_by_practice_name}
//       </td>
//       <td className="p-2 border border-gray-400">
//         {patient?.type_of_referral}
//       </td>
//       {/* <td className="p-2 border border-gray-400 uppercase">
//         {patient?.implant_site}
//       </td>
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.implant_system}
//       </td>
//       <td className="p-2 border border-gray-400">
//         {patient?.implant_diameter}
//       </td>
//       <td className="p-2 border border-gray-400">{patient?.implant_length}</td> */}
//       <td className="p-2 border border-gray-400 capitalize">
//         {patient?.status}
//       </td>
//       <td className="p-2 border border-gray-400">
//         <div className="w-full justify-center items-center flex gap-4">
//           <button
//             className="hover:underline"
//             onClick={() =>
//               navigate(`/referrals/patient_record/${patient?.id}`, {
//                 state: patient,
//               })
//             }
//           >
//             View Details
//           </button>
//         </div>
//       </td>
//     </tr>
//   );
// }
let pageNumber = 1;
const Referrals = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false)
  const [showPatientFormModal, setShowPatientFormModal] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [selectedArray, setSelectedArray] = useState([]);
  const [patientData, setPatientData] = useState([]);
  // const [searched, setsearched] = useState(false)
  const navigate = useNavigate();
  const [showFiltersPopup, setShowFiltersPopup] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data: patientData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      globalFilter: searchWord,
    },
    onGlobalFilterChange: setSearchWord,
  });

  const fetchReferralData = () => {
    setLoading(true)
    const endpoint = `${process.env.REACT_APP_API_URL}api/v1/getpatientrecordsvtwo`;
    const payload = {
      id: user?.id,
    };
    axios
      .get(endpoint, { withCredentials: true })
      .then((response) => {
        console.log(
          "data received from fetchReffarals: ",
          response?.data?.data?.patientRecords
        );
        setPatientData(response?.data?.data?.result);
        setLoading(false)
      })
      .catch((error) => {
        console.log("Error while fetching patient records: ", error);
        setLoading(false)
      });
  };

  const clearSearch = () => {
    // fetchReferralData()
    setSearchWord("");
    // setsearched(false)
  };

  // const handleSearch = () => {
  //   setsearched(true)
  //   const payload = {
  //     text: searchWord,
  //   };
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}api/v1/searchpatientrecords`,
  //       payload, {withCredentials: true}
  //     )
  //     .then((response) => {
  //       console.log("response from search: ", response?.data);
  //       setPatientData(response?.data?.data?.patientRecords);
  //     })
  //     .catch((error) => {
  //       console.log("error while searching: ", error);
  //     });
  // }

  // const handleFetchAllPatients = () => {

  // }

  const deletePatientRecords = ()=> {
    const payload = {
      // "id" : 1,
      "patient_id_list" : selectedArray
    }
    const url = `${process.env.REACT_APP_API_URL}api/v1/deletepatientrecords`;
    console.log('payload before deleting', payload)
    axios.delete(url, payload, {withCredentials:true})
    .then(response=> {
      console.log(response)
      console.log("Deleted Successfully!")
      setSelectedArray([])
    })
    .catch(error=> {
      console.log("Error while Deleting..")
      console.log(error)
    })
  }


  useEffect(() => {
    fetchReferralData();
  }, []);

  useEffect(()=> {
    table.setPageIndex(pageNumber-1)
  },[])

  if (user?.token === undefined) {
    navigate("/login")
  }

  console.log(selectedArray)
  return (
    <div className="w-full flex font-pop">
      <div className="w-full bg-white p-4 flex flex-col gap-6">
        {/*Action buttons to add new patient and search for patient records*/}

        <div className="w-full py-4 justify-between items-center flex">
          <div className="flex justify-center items-center gap-3 text-sm font-pop">
            {/* <button
              className="h-[40px] rounded-md cursor-pointer text-white bg-gray-700 px-3 font-medium  border-none outline-none "
              onClick={() => setShowPatientFormModal(true)}
            >
              Add Patient Record
            </button> */}
            <Link to="/referrals/CBCT-Referrals">
              <button className="h-[40px] rounded-md cursor-pointer text-white bg-[#F87D6A] px-3 font-medium  border-none outline-none ">
                CBCT Referral
              </button>
            </Link>

            <Link to="/referrals/OPG-Referrals">
              <button className="h-[40px] rounded-md cursor-pointer text-white bg-[#F87D6A] px-3 font-medium  border-none outline-none ">
                OPG Referral
              </button>
            </Link>

            <Link to="/referrals/Implant-Referrals">
              <button className="h-[40px] rounded-md cursor-pointer text-white bg-[#F87D6A] px-3 font-medium  border-none outline-none ">
                Implant Referral
              </button>
            </Link>
            <Link to="/referrals/surgical-guide-referral">
              <button className="h-[40px] rounded-md cursor-pointer text-white bg-[#F87D6A] px-3 font-medium  border-none outline-none ">
                Surgical Guide Referral
              </button>
            </Link>
            {/* {user?.role_id === 1 && (
              <button className="h-[40px] rounded-md cursor-pointer text-white bg-blue-500 px-3 font-medium  border-none outline-none" onClick={handleFetchAllPatients}>
                See All Patient Records
              </button>
            )} */}
          </div>
        </div>

        {/* Dialog for patient form */}

        <dialog
          open={showPatientFormModal}
          className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
        >
          <div className="w-full min-h-screen p-4 flex justify-center items-center">
            <div className="md:w-[600px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-3">
              {/* <div className='w-full flex justify-end items-center'>
                <button className="border-2 border-gray-300 p-2 rounded-md" onClick={() => setShowPatientFormModal(false)}>❌</button>
              </div> */}
              <PatientForm showModal={setShowPatientFormModal} />
            </div>
          </div>
        </dialog>

        {/*Patient records in tabular format*/}

        <div className="w-full flex flex-col items-start justify-start gap-5 min-h-[calc(100vh-300px)]">
          {patientData?.length > 0 && (
            <div className="w-full justify-between items-center flex">
              <p className="text-xl font-semibold">List of Patient Records</p>
              {/* Filters Division--> */}
              <div className="flex items-center gap-3">
                <div className="border rounded-full py-[10px] px-3 text-xs border-gray-300 flex gap-2 items-center">
                  {searchWord ? (
                    <button onClick={clearSearch}>
                      <svg
                        className="w-4 h-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                      </svg>
                    </button>
                  ) : (
                    <button>
                      <svg
                        className="w-4 h-4 text-gray-500 cursor-pointer"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  )}
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search"
                    className="w-[85%] outline-none"
                    value={searchWord}
                    onChange={(e) => setSearchWord(e?.target?.value)}
                  />
                </div>
                <StatusFilterButtonAndPopup 
                    showFiltersPopup={showFiltersPopup} 
                    setShowFiltersPopup={setShowFiltersPopup}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                />
                {selectedArray?.length > 0 && (
                  <>
                    <button className="h-[40px] hidden rounded-md cursor-pointer text-white bg-blue-500 px-3 font-medium  border-none outline-none">
                      Archive
                    </button>
                    <button 
                        onClick={deletePatientRecords}
                        className="rounded-full py-[10px] px-6  text-xs cursor-pointer text-[#F87D6A] font-medium  border border-[#F87D6A] outline-none active:bg-[#F87D6A] active:text-white active:scale-95">
                      Delete
                    </button>
                  </>
                )}
              </div>
              {/* <--- Filters Division */}
            </div>
          )}

          <div className="w-full overflow-x-auto">
            {loading ?
              <div className='flex justify-center overflow-hidden w-full mt-16'>
                <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
              </div>
            :
            patientData?.length > 0 ? (
              // <>
              // <table className="w-full rounded-md">
              //   <thead className="min-w-full text-sm rounded-md">
              //     <tr className="rounded-md bg-gray-600 text-white text-xs">
              //       <th className="p-2 border border-gray-400 font-medium"></th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Patient Name
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Assigned to
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Practice Name
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">Referred By</th>
              //       <th className="p-2 border border-gray-400 font-medium">Practice Name</th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Referral Type
              //       </th>
              //       {/* <th className="p-2 border border-gray-400 font-medium">
              //         Implant Site
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Implant System
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Implant Diameter
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Implant Length
              //       </th> */}
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Patient Status
              //       </th>
              //       <th className="p-2 border border-gray-400 font-medium">
              //         Action
              //       </th>
              //     </tr>
              //   </thead>
              //   <tbody className="bg-white divide-y divide-gray-200">
              //     {patientData?.map((patient, index) => (
              //       <TableRow
              //         patient={patient}
              //         key={patient?.patient_id}
              //         index={index}
              //         updateArray={setSelectedArray}
              //       />
              //     ))}
              //   </tbody>
              // </table>
              // </>
      
              <>
                <table className="w-full">
                  <thead className="min-w-full text-sm border-primary border">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className="rounded-t-md bg-primary text-white text-sm divide-x divide-gray-400"
                      >
                        {/* ****checkBox Column Header***** */}
                        {/* {user.role_id===1 && <th className="py-[9.5px] px-3  font-medium "></th>} */}
                        {/* ****checkBox Column Header***** */}
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            className={`py-[9.5px] px-3  text-start font-medium ${user?.role_id!==1 && ''} `}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {/* {header.column.columnDef.accessorKey ===
                              "status" && (
                              <StatusFilterButtonAndPopup
                                showFiltersPopup={showFiltersPopup}
                                setShowFiltersPopup={setShowFiltersPopup}
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                              />
                            )} */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white divide-y divide-[#C0C0C0] border-b border-[#C0C0C0]">
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        // className={`text-center text-xs ${
                        //   index % 2 === 0 && "bg-gray-100"
                        // }`}
                        
                        className={`divide-x divide-[#C0C0C0] border-x border-[#C0C0C0]  rounded-b-md text-start text-sm  hover:bg-[#dae1f072] ${selectedArray.some(patientInfo=> patientInfo.id===row?.original?.id) && 'bg-[#DAE1F0]'}`}
                      >
                        {/* *******Select Column***** */}
                        {/* {user.role_id===1 && 
                        <td className=" py-[9.5px] px-2 ">
                          <input
                            type="checkbox"
                            className="cursor-pointer accent-primary"
                            checked={selectedArray.some(selectedItem => selectedItem?.id === row?.original?.id)}
                            onChange={(e) => {
                              if (e?.target?.checked === true) {
                                console.log("selected");
                                setSelectedArray((prevSelected) => [
                                  ...prevSelected,
                                  row.original,
                                ]);
                              } else {
                                console.log("unselected");
                                setSelectedArray((prevSelected) =>
                                  prevSelected.filter(
                                    (selectedItem) =>
                                      selectedItem?.id !== row.original.id
                                  )
                                );
                              }
                            }}
                          />
                        </td>} */}
                        {/* *******Select Column***** */}
                        {row.getVisibleCells().map((cell) => {
                          // console.log('THIS IS CELL:::__\n',cell.column.columnDef.accessorKey)
                          // console.log('THIS IS ROW:::__\n',row)
                          return (
                            <td
                            onClick={() =>
                              navigate(
                                `/referrals/patient_record/${row?.original?.id}`,
                                { state: row?.original }
                              )
                            }
                              key={cell.id} //border border-[#C0C0C0] 
                              className=" py-[9.5px] px-2  capitalize"
                            >
                              {/* {console.log('CELL info In TABEL:::\n',cell)} */}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              {/* {cell.column.columnDef.accessorKey !==
                              "action_button" ? (
                                flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )
                              ) : (
                                <div className="w-full justify-center items-center flex gap-4">
                                  <button
                                    className="hover:underline"
                                    onClick={() =>
                                      navigate(
                                        `/referrals/patient_record/${row?.original?.id}`,
                                        { state: row?.original }
                                      )
                                    }
                                  >
                                    View Details
                                  </button>
                                </div>
                              )} */}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* {pagination Buttons} */}
                {table.getRowModel()?.rows?.length > 0 ? ( // pagination Btns are shown only if rows exist
                  <div className="w-full mt-5 flex justify-center items-center gap-4 text-sm font-pop">
                    <button
                      disabled={!table.getCanPreviousPage()}
                      onClick={() => {pageNumber=1; table.setPageIndex(0)}}
                      className={`h-[35px] rounded-md border  ${
                        table.getCanPreviousPage()
                          ? "border-gray-800 hover:bg-primary hover:border-primary"
                          : "border-gray-400 cursor-not-allowed text-gray-400"
                      } px-3 outline-none`}
                    >
                      <svg className={`w-6 h-6 ${table.getCanPreviousPage()? 'text-gray-800' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m17 16-4-4 4-4m-6 8-4-4 4-4"/>
                      </svg>
                    </button>
                    <button
                      disabled={!table.getCanPreviousPage()}
                      onClick={() => {pageNumber -=1;table.previousPage()}}
                      className={`h-[35px] rounded-md border  ${
                        table.getCanPreviousPage()
                          ? "border-gray-800 hover:bg-primary hover:border-primary"
                          : "border-gray-400 cursor-not-allowed text-gray-400"
                      } px-4 outline-none text-xl`}
                    >
                      <svg className={`w-4 h-4 ${table.getCanPreviousPage()? 'text-gray-800' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7"/>
                      </svg>
                    </button>
                    <span>
                      {table.getState().pagination.pageIndex + 1} of{" "}
                      {table.getPageCount()}
                    </span>
                    <button
                      disabled={!table.getCanNextPage()}
                      onClick={() => {pageNumber +=1;table.nextPage()}}
                      className={`h-[35px] rounded-md border  ${
                        table.getCanNextPage()
                          ? "border-gray-800 hover:bg-primary hover:border-primary"
                          : "border-gray-400 cursor-not-allowed text-gray-400"
                      } px-4 outline-none text-xl`}
                    >
                      <svg className={`w-4 h-4 ${table.getCanNextPage()? 'text-gray-800' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7"/>
                      </svg>
                    </button>
                    <button
                      disabled={!table.getCanNextPage()}
                      onClick={() =>
                        {pageNumber=table.getPageCount(); table.setPageIndex(table.getPageCount()-1)}
                      }
                      className={`h-[35px] rounded-md border  ${
                        table.getCanNextPage()
                          ? "border-gray-800 hover:bg-primary hover:border-primary"
                          : "border-gray-400 cursor-not-allowed text-gray-400"
                      } px-3 outline-none`}
                    >
                      <svg className={`w-6 h-6 ${table.getCanNextPage()? 'text-gray-800' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m7 16 4-4-4-4m6 8 4-4-4-4"/>
                      </svg>
                    </button>
                    {/* <div className='ml-4 text-md'>
                    <span>Rows per page : </span>
                    <select className=' border rounded-sm border-gray-800 py-1 px-2 outline-none cursor-pointer' value={table.getState().pagination.pageSize} onChange={e=> table.setPageSize(e.target.value)}>
                      {[10,15,20,30].map(size=> (
                        <option key={size} value={size}>{size}</option>
                      ))}
                    </select>
                  </div> */}
                  </div>
                ) : (
                  <p className="text-center mt-16">No records found..!</p>
                )}
              </>
            ) : (
              <div className="w-full flex justify-center items-center min-h-[300px]">
                <p>You've not referred any patients yet.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
