import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BasicTable from "../components/BasicTable";
import { toast } from "react-toastify";

const BookingDetails = () => {

  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(false)
  const user = JSON.parse(localStorage.getItem("userData"));
  const location = useLocation()
  const eventInfo = location.state

  const { eventId } = useParams();
  console.log(eventId);

  const navigate = useNavigate();

  const reqDateFormat = (date) => {
    const parts = date?.split("-");
    if (parts?.length === 3) {
      // Ensure there are three parts (year, month, and day)
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Subtract 1 from the month as it is 0-based
      const day = parseInt(parts[2]);

      const originalDate = new Date(year, month, day);

      // Format the date as desired
      const dayNumber = originalDate.getDate();
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "short"
      }).format(originalDate);
      const yearNumber = originalDate.getFullYear();

      const formattedDate = `${dayNumber} ${monthName} ${yearNumber}`;

      return formattedDate;
    } else {
      console.error("Invalid date format.");
      return "Invalid Date"; // You can return an error message or handle it differently
    }
  };

  //  const fetchBookingDetails = () => {
  //     axios.get(`${process.env.REACT_APP_API_URL}api/v1/AllBookings/20`, {
  //         withCredentials: true
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         //setBookingDetails(response?.data?.data?.data);
  //       })
  //       .catch((error) => {
  //         console.log("Error while fetching event by id: ", error);
  //         alert("Error while fetching event. Please try again.");
  //         navigate(-1);
  //       });
  //   };

  const fetchBookingDetails = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/AllBookings/${eventId}`, {
        withCredentials: true
      })
      .then((response) => {
        console.log(response);
        setBookingDetails(response?.data?.data?.response);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 404) {
          // Handle 404 status - No Bookings are done
          setBookingDetails([]); // Set an empty array or null based on your preference
        } else {
          console.log("Error while fetching event by id: ", error);
          toast.error("Error while fetching course bookings. Please try again.");
          // navigate(-1);
        }
      });
  };

  console.log(bookingDetails);

  useEffect(() => {
    fetchBookingDetails();
    // fetchBookingss();
  }, [eventId]);

  if(!user?.token){
    navigate('/login')
    return;
  }
  if(user?.role_id !== 1 ){
    navigate('/')
    return;
  }

  // if (bookingDetails === null)
  //   return (
  //     <div className="flex items-center justify-center h-full">Loading...</div>
  //   );

  /** @type import('@tanstack/react-table').ColumnDef<any> */
  const columns = [
//    {
//       header: "Name",
//      accessorKey: "user_details.first_name"
//     }, 
       {
      header: 'Name',
      accessorFn: row => `${row?.user_details?.title} ${row?.user_details?.first_name} ${row?.user_details?.last_name}`,
    },


    {
      header: "Booked Date",
      accessorKey: "booked_at"
    },
    {
      header: "Email",
      accessorKey: "user_details.email_id"
    },
  
  ];

  return (
    <>
      
        <div className="w-full bg-gray-100 p-4 select-none flex font-pop">
          <div className="w-full bg-white p-4 rounded-md shadow-lg   flex flex-col gap-6">
            {/* {bookingDetails} */}
            <div className="flex gap-4 gap-y-4 sm:flex-row flex-col justify-between w-full md:items-center">
              <button
                className="w-[50%] sm:w-[150px] justify-center items-center text-xs sm:text-sm cursor-pointer  flex p-2 px-4 font-medium border-black border rounded-md text-black"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
            <div className="border p-3">
              <div className="title-container">
                <div className="flex md:flex-row flex-col gap-y-5 w-full">
                  <div className="basis-[50%]">
                    <p className="sm:text-xl text-lg font-semibold">
                      {eventInfo.event_name}
                    </p>

                    <div className="w-full flex sm:flex-row flex-col py-5 sm:items-center gap-3">
                      <p className="text-gray-600 text-sm">
                        Max Seats:{" "}
                        <span className="font-medium text-gray-700">
                          {eventInfo.event_max_seats}
                        </span>
                      </p>
                      <p className="text-gray-600 text-sm">
                        Available Seats:{" "}
                        <span className="font-medium text-gray-700">
                          {eventInfo.event_available_seats}
                        </span>
                      </p>
                    </div>
                    <p className="text-gray-600 flex items-center gap-1 text-sm">
                      Course Fee:{" "}
                      <span className="font-medium text-base flex items-center text-[#e95f3c]">
                      <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="text-secondary font-medium h-4 w-4" viewBox="0 0 16 16">
  <path d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.3 5.3 0 0 1 2.103.465V2.456A5.6 5.6 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4z"/>
</svg>{eventInfo.event_fee}
                      </span>
                    </p>
                    <div className="w-full flex items-center py-5 gap-3">
                      <svg
                        className="w-[18px] h-[18px] text-gray-800"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                      </svg>
                      <p className="text-gray-600 text-sm">{`${reqDateFormat(
                       eventInfo.event_start_date
                      )?.replace(/2023/, "")} - ${reqDateFormat(
                        eventInfo.event_end_date
                      )}`}</p>
                    </div>
                    <div className="w-full flex items-center gap-3 pb-5">
                      <svg
                        className="sm:w-[18px] sm:h-[18px] w-[18px] text-gray-800"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 16 20"
                      >
                        <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                      </svg>
                      <p className="text-gray-600 text-sm">{`${eventInfo.event_place}, ${eventInfo.event_street}, ${eventInfo.event_city}, ${eventInfo.event_country}`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="agenda-container flex flex-col gap-4 items-start justify-center">
                <p className="font-medium text-lg w-full">All Bookings</p>
                {/* <GenerateTables data={bookingDetails[0]?.schedule} /> */}
                {/* {bookingDetails && (
                  <BasicTable data={bookingDetails} columns={columns} />
                )} */}
                 {loading?
                <div className='flex justify-center w-full mt-2'>
                  <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
              </div>
                : bookingDetails?.length>0?
                bookingDetails && (
                  <BasicTable data={bookingDetails} columns={columns} />
                )
              :<p className="font-medium text-center pt-4 -mt-2 my-5 border-t border-gray-300 w-full">No Bookings found</p>}
              </div>
            </div>
            
          </div>
        </div>
      
       
    </>
  );
};

export default BookingDetails;
