import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./../assets/cognismiles-logo.png";

const EmailSent = () => {
  const navigate = useNavigate()
  return (
    <div className="flex justify-center items-center bg-gray-200 min-h-screen">
        <div className="rounded-md shadow-lg p-5 mx-4 bg-white">
            <h1 className="text-xl font-medium mb-8">Email Sent</h1>
            <p className="leading-relaxed">A verification link has been sent to your registered email.
                <br/>
                Please verify your account.
            </p>
            <button onClick={()=> navigate('/login')} className="border w-full bg-secondary text-white py-2 px-5 rounded-md mt-4">Got it!</button>
        </div>
    </div>
  )
}

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [title , setTitle] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [number, setNumber] = useState("")
    const [practice, setPractice] = useState("")
    // new states
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)
    const [practiceAddress, setPracticeAddress] = useState("")
    const [gdcNumber, setGdcNumber] = useState("")
    const [postCode, setPostCode] = useState("")
    const [showPassword , setShowPassword] = useState("")
    const [showConfirmPassword , setShowConfirmPassword] = useState("")

  const roleId = 2

  const options = [
    {
      role_id: 1,
      role: "Admin",
    },
    {
      role_id: 2,
      role: "Student",
    },
  ];

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRegistration = () => {
    if (password === confirmPassword) {
      setIsSubmitting(true)
      const payload = {
        title:title+".",
        first_name: firstName,
        last_name: lastName,
        practice_name: practice.trim(),
        phone_number: number,
        email_id: email,
        password: password,
        role_id: 2,
        // 3 missed values are added  below
        practice_address: practiceAddress.trim(),
        gdc_number: gdcNumber,
        post_code: postCode.toUpperCase()
      };
      const url = `${process.env.REACT_APP_API_URL}api/v1/register`;
      axios
        .post(url, payload, {withCredentials:true})
        .then((response) => {
          console.log("Registered successfully! ", response?.data);
          // alert("Registration Sucessful! Please login to continue");
          // navigate("/login");
          console.log("Registered successfully! ")
          setIsSubmitting(false)
          setIsRegistered(true)
        })
        .catch((error) => {
          setIsSubmitting(false)
          console.log("Error while registering: ", error);
        });
    } else {
      alert("Passwords do not match");
    }
  };

  if(isRegistered){
    return <EmailSent/>
  }

  return (
    <section class=" bg-gray-50 h-full py-10  font-pop">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        {/* <p class="flex items-center mb-6 text-2xl font-semibold text-gray-900 ">
          <img class="w-8 h-8 mr-2" src={logo} alt="logo" />
          Cognismiles
        </p> */}
        <div class="w-full md:min-w-[700px] bg-white rounded-lg shadow-md md:mt-0 sm:max-w-md xl:p-0 ">
        <img className="w-[100px] h-auto mx-auto" src={logo} alt="logo" />
          <div class="p-4  space-y-4 md:space-y-6 sm:px-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Create an Account
            </h1>
            <form
              class="space-y-4 md:space-y-6"
              onSubmit={(e) => {
                e?.preventDefault();
                handleRegistration();
              }}
            >
              <div className="w-full flex gap-3">
              <div className="w-24">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-primary "
                  >
                    Title
                    <span className="text-red-500">*</span>
                  </label>


                  <select
                    value={title}
                    onChange={(e) => setTitle(e?.target?.value)}
                    name="title"
                    id="title"
                    className="invalid:text-gray-400 outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                    placeholder="Select Title"
                    required
                  >
                    <option value="" disabled>Title</option>
                    <option value="Mr" className="text-black">Mr</option>
                    <option value="Dr" className="text-black">Dr</option>
                    <option value="Ms" className="text-black">Ms</option>
                    <option value="Mrs" className="text-black">Mrs</option>
                  </select>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="fName"
                    className="block mb-2 text-sm font-medium text-primary "
                  >
                    First Name 
                    <span className="text-red-500">*</span>
                  </label>

                  <input
                    value={firstName}
                    // onChange={(e) => setFirstName(e?.target?.value)}
                    onChange={(e) => {
                      const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                      if(containsOtherChars){
                        const otherChar = containsOtherChars[0]
                        e.target.value = e.target.value.replace(otherChar, '')
                        setFirstName(e.target.value)
                      }else{
                        setFirstName(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="first_name"
                    id="fName"
                    className="outline-none shadow-sm border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                    placeholder="John"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="lName"
                    className="block mb-2 text-sm font-medium text-primary "
                  >
                    Last Name
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={lastName}
                    // onChange={(e) => setLastName(e?.target?.value)}
                    onChange={(e) => {
                      const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                      if(containsOtherChars){
                         const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                        setLastName(e.target.value)
                      }else{
                        setLastName(e?.target?.value)
                      }
                    }}
                    type="text"
                    name="last_name"
                    id="lName"
                    className="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>
              
              <div className="w-full flex gap-3">
                <div className="w-1/2">
                <label
                  htmlFor="post_code"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Post Code
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="post_code"
                  id="post_code"
                  class="shadow-sm uppercase outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="M5 4SY"
                  value={postCode}
                  // onChange={(e) => setPostCode(e?.target.value)}
                  minLength={4}
                  maxLength={10}
                  onChange={(e) => {
                    const containsOtherChars = e.target.value.match(/[^0-9 a-zA-Z]/)
                    if(containsOtherChars){
                       const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                      setPostCode(e.target.value)
                    }else{
                      setPostCode(e?.target?.value)
                    }
                  }}
                  required
                />
                </div>
                <div className="w-1/2">
                <label
                  htmlFor="phone_number"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Phone Number
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="0131 123 4567"
                  value={number}
                  // onChange={(e) => setNumber(e?.target.value)}
                  minLength={7}
                  maxLength={15}
                  onChange={(e) => {
                    const containsOtherChars = e.target.value.match(/[^0-9]/)
                    if(containsOtherChars){
                       const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                      setNumber(e.target.value)
                    }else{
                      setNumber(e?.target?.value)
                    }
                  }}
                  required
                />
                </div>
              </div>
              
              {/* Practice name & GDC...  pair fields - start */}
              <div className="w-full flex gap-3">
                <div className="w-1/2">
                <label
                  htmlFor="practice_name"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Practice Name
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="practice_name"
                  id="practice_name"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="Dental Clinic"
                  value={practice}
                  // onChange={(e) => setPractice(e?.target.value)}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimStart();
                    const containsOtherChars = e.target.value.match(/[^a-zA-Z ]/)
                    if(containsOtherChars){
                       const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                      setPractice(e.target.value)
                    }else{
                      setPractice(e?.target?.value)
                    }
                  }}
                  required
                />
                </div>
                <div className="w-1/2">
                <label
                  htmlFor="gdc_number"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  GDC Number
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="gdc_number"
                  id="gdc_number"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="GDC Number"
                  value={gdcNumber}
                  // onChange={(e) => setGdcNumber(e?.target.value)}
                  onChange={(e) => {
                    const containsOtherChars = e.target.value.match(/[^0-9]/)
                    if(containsOtherChars){
                       const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                      setGdcNumber(e.target.value)
                    }else{
                      setGdcNumber(e?.target?.value)
                    }
                  }}
                  required
                />
                </div>
              </div>
              {/* Practice name & GDC... pair - end */}
              {/* <div>
                <label
                  htmlFor="email"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Practice Name
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="Dental Clinic"
                  value={practice}
                  onChange={(e) => setPractice(e?.target.value)}
                  required
                />
              </div> */}
              {/* missed fields --> */}
              <div>
                <label
                  htmlFor="practice_address"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Practice Address
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="practice_address"
                  id="practice_address"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="Practice Address"
                  value={practiceAddress}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimStart();
                    setPracticeAddress(e?.target.value)
                  }}
                  required
                />
              </div>
              {/* <div>
                <label
                  htmlFor="practice_address"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  GDC Number
                </label>
                <input
                  type="text"
                  name="gdc_number"
                  id="gdc_number"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="GDC Number"
                  value={gdcNumber}
                  onChange={(e) => setGdcNumber(e?.target.value)}
                  required
                />
              </div> */}


              {/* <div>
                <label
                  htmlFor="practice_address"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Post Code
                </label>
                <input
                  type="text"
                  name="post_code"
                  id="post_code"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="Post Code"
                  value={postCode}
                  onChange={(e) => setPostCode(e?.target.value)}
                  required
                />
              </div> */}
              {/* <---missed fields are added */}

              {/* <div>
                <label
                  htmlFor="phone_number"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="+44XXXXXXXXXXXX"
                  value={number}
                  onChange={(e) => setNumber(e?.target.value)}
                  required
                />
              </div> */}
              <div>
                <label
                  htmlFor="email"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Your email
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e?.target.value)}
                  // onChange={(e) => {
                  //   const containsOtherChars = e.target.value.match(/[^0-9a-zA-Z@.]/)
                  //   if(containsOtherChars){
                  //      const otherChar = containsOtherChars[0]
                  //   e.target.value = e.target.value.replace(otherChar, '')
                  //     setEmail(e.target.value)
                  //   }else{
                  //     setEmail(e?.target?.value)
                  //   }
                  // }}
                  required
                />
              </div>
              <div className="w-full flex gap-3">
                <div className="w-1/2">
                <label
                  htmlFor="password"
                  className="block  mb-2 text-sm font-medium text-primary "
                >
                  Password
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  value={password}
                  onChange={(e) => {
                    setPassword(e?.target.value.replace(" ",''))
                  }}
                  required
                />
                {showPassword ? 
                <svg onClick={handleShowPassword} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                  <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg> : 
                <svg onClick={handleShowPassword} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
                }
                
                  </div>
                </div>
                <div className="w-1/2">
                <label
                  htmlFor="confirm-password"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Confirm password
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e?.target.value.replace(" ",''))}
                  required
                />
                {showConfirmPassword ? 
                <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-width="2" d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"/>
                  <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg> : 
                <svg onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="w-6 h-6 text-gray-400 absolute top-[20%] right-2 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
                }
                </div>
                </div>
              </div>
              {/* <div>
                <label
                  htmlFor="password"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  value={password}
                  onChange={(e) => setPassword(e?.target.value)}
                  required
                />
              </div> */}
              {/* <div>
                <label
                  htmlFor="confirm-password"
                  class="block mb-2 text-sm font-medium text-primary "
                >
                  Confirm password
                </label>
                <input
                  type="confirm-password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  class="shadow-sm outline-none border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e?.target.value)}
                  required
                />
              </div> */}

              <div class="flex items-start">
                {/* <div class="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded shadow-sm focus:ring-3 focus:ring-blue-300"
                    required
                  />
                </div> */}
                {/* <div class="ml-3 text-sm">
                  <label htmlFor="terms" class=" text-gray-700 ">
                    I accept the{" "}
                    <a href="#"><span class="font-medium text-blue-600 hover:underline  cursor-pointer">
                      Terms and Conditions
                    </span></a>
                  </label>
                </div> */}
              </div>
              <div className="flex flex-col gap-5 justify-center items-center">
              <button
                type="submit"
                class="flex justify-center text-white bg-secondary focus:ring-4 focus:outline-none focus:ring-secondary font-medium rounded-lg text-sm px-8 py-2.5 text-center "
              >
                {isSubmitting? 
                <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
                  <g stroke="white"><circle cx="12" cy="12" r="9.5" fill="none" strokeLinecap="round" strokeWidth="2">
                  <animate attributeName="stroke-dasharray" calcMode="spline" dur="1.5s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0 150;42 150;42 150;42 150"/><animate attributeName="stroke-dashoffset" calcMode="spline" dur="1.5s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0;-16;-59;-59"/></circle><animateTransform attributeName="transform" dur="2s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></g>
                </svg> 
                : 'Create an account'}
              </button>
              <p class="text-sm text-gray-700 ">
                Already have an account?{" "}
                <span
                  class="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login here
                </span>
              </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
