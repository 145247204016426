export const tableColumns = [
  {
    header: "Patient Name",
    accessorFn: (row) => `${row.title} ${row.first_name} ${row.last_name}`,
  },
  {
    header: "Assigned to",
    accessorFn: (row) =>
      `${row.assigned_doctor_title} ${row.assigned_doctor_first_name} ${row.assigned_doctor_last_name}`,
  },
  {
    header: "Practice Name",
    accessorKey: "assigned_doctor_practice_name",
  },
  {
    header: "Referred by",
    accessorFn: (row) =>
      `${row.created_by_title} ${row.created_by_first_name} ${row.created_by_last_name}`,
  },
  {
    header: "Practice Name",
    accessorKey: "created_by_practice_name",
  },
  {
    header: "Referral Type",
    accessorKey: "type_of_referral",
  },
  {
    header: "Patient Status",
    accessorKey: "status",
    enableColumnFilter: true,
    filterFn: (row, columnId, filterValues) => {
      if (filterValues.length === 0) {
        return true;
      }
      const status = row.getValue(columnId);
      return filterValues.includes(status);
    },
  },
  // {
  //   header: "Actions",
  //   accessorKey: "action_button",
  // },
];



