// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom'

// const Bookings = () => {
//   const user = JSON.parse(localStorage.getItem("userData"));
//   console.log('User Details:',user)

//   const [bookingData, setBookingData] = useState([])
//   const navigate = useNavigate()


  // const fetchBookings = () => {
  //   axios.get(`${process.env.REACT_APP_API_URL}api/v1/myBookings`, {withCredentials:true})
  //   .then((response) => {
  //     console.log("response from fetchbookings: ", response?.data)
  //     setBookingData(response?.data?.data?.response)
  //   })
  //   .catch((error) => {
  //     console.log("error while fetchBookings: ", error)  
  //   })
  // }

//   useEffect(() => {
//     fetchBookings()
//   },[])

//   if (!bookingData)
//       return (
//         <div className="flex items-center justify-center h-full">Loading...</div>
//       );


//   return (
//     <div className="w-full bg-gray-100 p-4 select-none flex font-pop min-h-[calc(100vh-90px)]">
//       <div className="w-full bg-white p-4 rounded-md shadow-lg flex flex-col gap-8">
//         <p className="font-medium text-lg">Bookings</p>
//         <div className="flex md:flex-row flex-col gap-y-5 gap-5 items-center">
//           {bookingData?.map((booking, index)=>{
//             return (
//               <div
//                 key={"booking_id:" + index}
//                 className="p-2 shadow-md rounded-md border pb-4 bg-white gap-3 flex flex-col sm:w-[420px] text-xs"
//               >
//                 <div>
//                   <p className="font-medium text-lg">{booking?.event_name}</p>
//                 </div>
//                 <div className="w-full flex gap-2 items-center">
//                   <svg
//                     className="w-5 h-5 text-gray-800"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 20 20"
//                   >
//                     <path
//                       fill="currentColor"
//                       d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
//                     />
//                   </svg>
//                   <p>
//                     {booking?.event_start_date} - {booking?.event_end_date}
//                   </p>
//                 </div>
//                 <div>
//                   <p>Fee: €{booking?.event_fee}</p>
//                 </div>
//                 <div className="w-full flex gap-2 items-center">
//                   <svg
//                     className="w-5 h-5 text-gray-800"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 16 20"
//                   >
//                     <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
//                   </svg>
//                   <p>
//                     {booking?.event_place}, {booking?.event_street},{" "}
//                     {booking?.event_city}
//                   </p>
//                 </div>
//                 <div className="w-full justify-center flex  items-center">
//                  <button  onClick={()=>{navigate(`/event_details/${booking?.event_id}`)}} className="bg-[#E64D26] text-white font-medium rounded-md p-2 px-4">More Details</button>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Bookings;



import axios from "axios";
import React, { useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Bookings = () => {

  const user = JSON.parse(localStorage.getItem("userData"));
  console.log('User Details:',user)
  const isAdmin = user?.role_id===1

  const [coursesData, setCoursesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  // const settings = {
  //   dots: false,
  //   fade: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   waitForAnimate: false,
    // autoplay: true,
    // autoplaySpeed: 5000
  // };

  const reqDateFormat = (date) => {
    const originalDate = new Date(date);
    const day = originalDate.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(originalDate);
    const year = originalDate.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate
}



  const fetchAllCourses = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/get-all-courses`, {
        withCredentials: true
      })
      .then((response) => {
        console.log(
          "response from fetchAllCourses: ",
          response?.data?.data?.data
        );
        console.log(response?.data?.data?.data[0]?.event_id);
        setCoursesData(response?.data?.data?.data);
        setIsLoading(false);
        console.log("resss",  coursesData)
      })
      .catch((error) => {
        console.log("error while fetchBookings: ", error);
        setIsLoading(false);
      });
  };

const fetchUserBookings = () => {
  setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}api/v1/myBookings`, {withCredentials:true})
    .then((response) => {
      console.log("response from fetchbookings: ", response?.data)
      setCoursesData(response?.data?.data?.response)
      setIsLoading(false);
    })
    .catch((error) => {
      console.log("error while fetchBookings: ", error) 
      setIsLoading(false); 
    })
  }


  const fetchBookings = ()=> {
    if(isAdmin){
      fetchAllCourses()
    }else{
      fetchUserBookings()
    }
  }

  // const arrows = coursesData?.images?.Data.length > 0 ? true : false;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows:true,
    slidesToScroll: 1,
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  if (user?.token === undefined) {
    navigate("/login")
  }
 
  return (
    <div className="w-full flex font-pop ">
      <div className="w-full p-4 flex flex-col gap-8">
        <p className="font-semibold text-2xl pt-1">Bookings</p>
        <div className="flex gap-y-5 flex-wrap gap-6">
          {isLoading?
            <div className='flex justify-center w-full mt-20'>
                <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
            </div>
         :
          coursesData?.length === 0 ?  <div className="text-lg m-4"> No Bookings found!</div>
          :
          coursesData?.map((booking, index) => {
           booking = isAdmin? booking : {...booking, images : JSON.parse(booking.images)}
            return (
              <>
              <div key={"booking_id:" + index} className="flex-col flex">
              <div className="w-[300px] bg-primary rounded-t-md">
                  <p className="font-medium text-white capitalize px-2 py-2 truncate">{booking?.event_name}</p>
                </div>
              <div
                 //{booking?.event_id}
                className="p-2 shadow-md rounded-b-md border pb-4 bg-white gap-3 flex flex-col w-[300px] text-xs"
              >
                 <div className="w-full overflow-hidden h-[200px] border rounded-md">
                  <Slider  {...settings}>
                      {booking?.images?.Data?.map((item,index) => (    
                       
                      <img className='object-cover w-full h-[200px] rounded-md' 
                            src={item.url} 
                            alt='random'
                            key={'image' + index}
                        />))
                      }
                  </Slider>
                  </div>
                {/* //Demo images     */}
                {/* <Slider ref={slider=> (sliderRef = slider)} {...settings}>
                 <img className="h-48 w-full object-cover rounded-lg" src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="" />
                 <img className="h-48 w-full object-cover rounded-lg" src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                </Slider> */}
                
                <div className="">
                  {/* <svg class="w-5 h-5 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10h9.2M6 14h9.2M18 5a6 6 0 0 0-3.4-1C11 4 7.8 7.6 7.8 12s3 8 6.8 8a6 6 0 0 0 3.4-1"/>
  </svg> */}
  
  <p className="text-primary text-lg flex items-center font-medium"><svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="text-primary h-[18px] w-[18px]" viewBox="0 0 16 16">
  <path d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.3 5.3 0 0 1 2.103.465V2.456A5.6 5.6 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4z"/>
</svg>{booking?.event_fee}</p>
                </div>
                <div className="w-full flex gap-2 py-1 items-center">
                  <svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
                    />
                  </svg>
                  <p>
                    {/* {booking?.event_start_date} - {booking?.event_end_date} */}
                    {`${reqDateFormat(booking?.event_start_date)?.replace(/2023/, '')} - ${reqDateFormat(booking?.event_end_date)}`}
                  </p>
                </div>
               
                <div className="w-full py-1 flex gap-2 items-center">
                  <svg
                    className="w-4 h-4 text-primary"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                  </svg>
                  <p className="truncate">
                    {booking?.event_place}, {booking?.event_street},{" "}
                    {booking?.event_city}
                  </p>
                </div>
                <div className="w-full justify-start flex  items-center">
                  <button
                    onClick={() => {
                      isAdmin? navigate(`/booking_details/${booking?.event_id}`, {state: booking})
                        : navigate(`/event_details/${booking?.id}`);
                    }}
                    className="border border-secondary text-secondary font-medium rounded-md p-2 px-4"
                  >
                    More Details
                  </button>
                </div>
              </div>
              </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Bookings;
