import React, { useEffect, useRef, useState } from 'react'
import AWS from 'aws-sdk'
import axios from 'axios'
import {toast} from "react-toastify";


const user = JSON.parse(localStorage.getItem("userData"));
const token = user?.token;
// const headers = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${token}`,
// };

const patientStatus = [
  {
    id: 1,
    status: "New Entry",
  },
  {
    id: 2,
    status: "Awaiting Planning",
  },
  {
    id: 3,
    status: "More Info Required",
  },
  {
    id: 4,
    status: "Awaiting Approval",
  },
  {
    id: 5,
    status: "Amend Plan",
  },
  {
    id: 6,
    status: "Approve Plan",
  },
  {
    id: 7,
    status: "Guide Posted",
  },
  {
    id: 8,
    status: "Approved",
  },
];

const filesList = [
  {
    id: 1,
    name: "Implant Plan",
  },
  {
    id: 2,
    name: "Surgical Guide",
  },

  {
    id: 3,
    name: "DICOM File",
  },

  {
    id: 4,
    name: "STL File",
  }
];

// props
const FileUploadForm = ({ showModal, patient_id, refreshPatientDetails, type_of_referral,currentStatus }) => {
  const [file, setFile] = useState(null);
  const [date, setDate] = useState("");
  const [status, setStatus] = useState();
  const [fileType, setFileType] = useState("");
  const [fileTypeError, setFileTypeError] = useState("");
  const [downloadUrl, setDownloadUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState();
  //below 5 lines added  
  const user = JSON.parse(localStorage.getItem("userData"));
  const [statusOptions, setStatusOptions] = useState()
  const [presignedUrl, setPresignedUrl] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false) 
  const fileRef = useRef()
    const cleanUp = () => {
        setFile(null)
        if(fileRef.current){
          fileRef.current.value=null
        }
        setDate("")
        setFileTypeError("")
        // setStatus("")
        setFileType("")
        // setDownloadUrl(null)
        setIsFileUploaded(false) //added
        showModal(false)
      
    }

  const addNewFile = (uploadedFileUrl) => { // aurgs
    const payload = {
      patient_id: patient_id,
      status_id: Number(status),
      file_type: fileType,
      // file_url: downloadUrl,
      file_url: uploadedFileUrl.split('?')[0],
      scan_taken_date: date,
      uploaded_by: user?.id,
    };
      console.log("payload before hitting backend: ", payload)
    axios.post(`${process.env.REACT_APP_API_URL}api/v1/addfilestopatientrecord`, payload, {withCredentials:true})
    .then((response) => {
      setIsSubmitting(false)
      setIsFileUploaded(true)
      refreshPatientDetails()
        // alert("File uploaded successFully")
        toast.success('File uploaded successfully!', {delay:500})
        console.log("response from file uploaded: ", response?.data)
        cleanUp()
    })
    .catch((error) => {
      setIsSubmitting(false)
        console.log('error while submitting file upload info: ', error)
        setFileTypeError(error?.response?.data?.data?.message)
    })
  };
  
  // below API func added
  const fetchPatientStatus = ()=> {
    const url = `${process.env.REACT_APP_API_URL}api/v1/getstatuslist/${type_of_referral}`
    axios.get(url, { withCredentials: true })
    .then(response=> {
      const statusList = response.data.data.result
      const currentStatusId = statusList.filter(status=> status.status===currentStatus)[0].id
      setStatus(currentStatusId)
      setStatusOptions(statusList)
    })
    .catch(error=> {console.log(error.message)})
  }


    // useEffect(() =>{
    //     if (downloadUrl) {
    //         addNewFile()
    //     }
    //     // console.log(patient_code);
    // },[downloadUrl])

  const handleFileChange = (e) => {
    if(e.target.files.length===0){
      return
    }
    const file = e.target.files[0];
    const allowedTypes = ['image/', 'video/', 'application/pdf', 'application/msword', 
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
                          'application/vnd.ms-powerpoint', 
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                          'text/plain', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'model/stl', 'application/dicom'];
    
    const notFileTypes = !allowedTypes.some(type => file.type.startsWith(type));
    if (notFileTypes) {
      setFileTypeError("file type not supported")
      return;
    }else {
      setFileTypeError('')
    }
    
    setFile(e.target.files[0]);
  };

  // const handleUpload = async () => {
  //   if (!file) {
  //     console.error("Please select a file.");
  //     return;
  //   }

  //   const s3 = new AWS.S3({
  //     accessKeyId: "AKIAU7OHNFO5I3II3FEN",
  //     secretAccessKey: "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",
  //     region: "us-east-2",
  //   });

  //   const bucketName = "cognismiles";
  //   const key = `${patient_id}-${file?.name}`;

  //   try {
  //     // Upload the file
  //     const uploadParams = { Bucket: bucketName, Key: key, Body: file };
  //     const managedUpload = s3.upload(uploadParams);

  //     // Listen for the httpUploadProgress event to get upload progress
  //     managedUpload.on("httpUploadProgress", (progress) => {
  //       const percentageProgress = Math.round(
  //         (progress.loaded / progress.total) * 100
  //       );
  //         setUploadProgress(percentageProgress);
  //         console.log(percentageProgress)
  //     });

  //     // Wait for the upload to complete
  //     const data = await managedUpload.promise();
  //     //   await s3.upload(uploadParams).promise();

  //     // Generate a presigned URL for downloading
  //     const downloadParams = {
  //       Bucket: bucketName,
  //       Key: key,
  //       Expires: 604800,
  //     };
  //     const downloadUrl = await s3.getSignedUrlPromise(
  //       "getObject",
  //       downloadParams
  //     );

  //       // setUploadUrl(`File uploaded to S3: s3://${bucketName}/${key}`);
  //       console.log("Url generated from aws: ", downloadUrl)
  //       setDownloadUrl(downloadUrl);
  //       //Running addNewFile function from here
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  const getPresignedUrl = async ()=> { // added
    try{
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/getSignedurl?contenttype=${file.type}&filename=${file.name}`,{withCredentials:true})
      if (response.data && response.data.data){
        console.log('getPresignedurl',response?.data?.data);
        setPresignedUrl(response.data.data)
        return response.data.data
      }else{
        console.error('Presigned URL not found in the response.');
        return null
      }
    }catch(error){
      console.error('Error while fetching presignedUrl')
    }
  }

  const uploadFile = async ()=> { // added
  
    try{
      const presignedUrlResponse = await getPresignedUrl()
      if(!presignedUrlResponse){
          console.error('Presigned URL is null or undefined.');
          setIsSubmitting(false)
          return
      }
      await axios.put(presignedUrlResponse.url, file, {withCredentials:true, 'Content-Type':file.type})
      console.log('FIle Uploaded Successfully!!!')
      addNewFile(presignedUrlResponse.url)
    }catch(error){
      setIsSubmitting(false)
      console.error('Error uploading file:', error);
      if(error.response){
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
      }else if(error.request){
        console.error('No response received:', error.request);
      }else{
        console.error('Request setup error:', error.message);
      }
    }
  }



  // replace below func
  const handleNewFileSubmission = () => {
    // handleUpload();
    if (!file) {
      // console.error("Please select a file.");
      toast.error('Please select a file')
      return;
    }
    setIsSubmitting(true)
    uploadFile()
  };

  useEffect(()=> { // added effect
    fetchPatientStatus()
},[])

// if (isFileUploaded) // added block
// return(
//   <div className='fixed inset-0 z-50 w-full flex justify-center items-center  bg-black bg-opacity-30 backdrop-blur-sm backdrop-filter'>
//     <div className='bg-white flex justify-center items-center text-center px-10 mx-5 py-5 max-w-[350px]  rounded-md shadow-md relative'>
//       <div className='flex flex-col items-center'>
//         <svg className="w-24 h-24 mb-4 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
//           <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
//         </svg>
//         <p className='text-base mb-5'>File Uploaded Successfully!</p>
//         <button onClick={() => {cleanUp();showModal(false)}} className='bg-secondary text-white  font-medium rounded-md shadow-sm w-full py-1'>OK</button>
//       </div>
//     </div>

//   </div>
// )
  // return (
  // <div className='flex flex-col items-center gap-2 pt-3'>
  //   <svg className="w-12 h-12 text-primary " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
  //   <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clipRule="evenodd"/>
  // </svg>
  
  //   <p>File Uploaded Successfully!</p>
  //   <button
  //           className="w-[90px] h-[35px] mt-10 font-medium text-sm rounded-md border border-gray-300"
  //           onClick={() => {cleanUp();showModal(false)}}
  //         >
  //           OK
  //         </button>

  //   </div>
  // )

  // console.log()

  const minDate = new Date()
  minDate.setDate(new Date().getDate() - 30)
  const maxDate = new Date()
  maxDate.setFullYear(new Date().getFullYear() + 5)


  return (
    <div className="w-full flex flex-col justify-center items-start gap-6">
      <div className="w-full p-2 flex justify-center items-center text-center">
        <p className="text-lg font-medium">File Upload Form</p>
      </div>
      <form
        onSubmit={(e) => {
          e?.preventDefault();
          // addNewFile();
          handleNewFileSubmission();

        }}
        className="flex flex-col gap-4 w-full mt-4"
      >
        <div className="w-full">
          <label
            htmlFor="patient_status"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Patient Status
          </label>
          <select
            name="patient_status"
            id="patient_status"
            className="w-full border border-gray-300 rounded-md text-sm p-2"
            onChange={(e) => setStatus(Number(e?.target?.value))}
            value={status}
          >
            <option value="" disabled>Select Patient Status</option>
            {statusOptions?.map((status) => (
              <option key={status?.id} value={status?.id} className="text-sm ">
                {status?.status}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label
            htmlFor="date"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Scanning Date
          </label>
          <input
            type="date"
            // min={new Date().toISOString().split("T")[0]}
            name="date"
            id="date"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none "
            placeholder="mm/dd/yyyy"
            max={maxDate.toISOString().split("T")[0]}
            min={minDate.toISOString().split("T")[0]}
            value={date}
            onChange={(e) => setDate(e?.target.value)}
            required
          />
        </div>

        <div className="w-full">
          <label
            htmlFor="assign_to"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            File type
          </label>
          <select
            name="assign_to"
            id="assign_to"
            className="w-full border border-gray-300 rounded-md text-sm p-2 outline-none"
            value={fileType}
            required
            onChange={(e) => setFileType(e?.target?.value)}
          >
            <option value="" disabled>Select File Type</option>
            {filesList?.map((file) => (
              <option
                key={file?.id}
                value={file?.name}
                className="text-sm text-black capitalize"
              >
                {file?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full rounded-md  flex flex-col justify-center items-center gap-6">
          <label
            htmlFor="file_upload"
            className="w-full flex flex-col justify-center items-center gap-4 p-4 border border-[#E6EDFF] rounded-md"
          >
            <span>
              <svg
                className="w-6 h-6 text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 21 16"
              >
                <path d="M11 16a1 1 0 0 1-1-1v-1h2v1a1 1 0 0 1-1 1Z" />
                <path d="M17.989 6.124a6.5 6.5 0 0 0-12.495-2.1A5 5 0 0 0 6 14h4V8.414l-.293.293a1 1 0 0 1-1.414-1.414l2-2a1 1 0 0 1 1.414 0l2 2a1 1 0 1 1-1.414 1.414L12 8.414V14h5a4 4 0 0 0 .989-7.876Z" />
              </svg>
            </span>
            {file ? (
              <span className="truncate w-[95%]">{file.name}</span>
            ) : (
              <span>Click here to upload file</span>
            )}
          </label>
          <input
            style={{ display: "none" }}
            type="file"
            accept='image/*, video/*, .pdf, .doc, .docx, .ppt, .pptx, .text, .xlsx, .stl, .dcm'
            name="file_upload"
            id="file_upload"
            ref={fileRef} 
            onChange={handleFileChange}
          
          />
          {/* <button
            onClick={handleUpload}
            className="bg-[#e95f3c] w-full rounded-md h-[38px] text-white"
          >
            Upload
          </button> */}
          {
            fileTypeError &&
            <p className='text-red-500'>{fileTypeError}</p>
          }
          
        </div>
        <div className="w-full flex gap-4 justify-center items-center p-2 mt-4">
          <button
            type="submit"
            className="w-[90px] h-[35px] font-medium text-sm rounded-md bg-secondary text-white"
            disabled={isSubmitting}
            // onClick={handleNewFileSubmission}
          >
            {isSubmitting? 
              <svg aria-hidden="true" className="inline w-4 h-4 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg> 
            : 'Submit'}
          </button>
          <button
          type='button'
            className="w-[90px] h-[35px] font-medium text-sm rounded-md text-secondary border border-secondary"
            onClick={() => {
               
               cleanUp()
              }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default FileUploadForm