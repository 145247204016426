import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement, Elements, PaymentElement, PostalCodeElement, CardNumberElement, CardCvcElement, CardExpiryElement, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


// const stripePromise = loadStripe('pk_test_51O1i2USFSA3uoRUNZhI0JGHlTZVzmt2iVtJWKMXz4ytFTDw6dMjct4DZ4GhfoXwZrSObxbi67X2o1R07CtHKOiGj00qH9jsxXM');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

// consoles
console.log('ENV PK:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
console.log('ENV SK Endpoint:', process.env.REACT_APP_GET_SECRET_KEY_API_ENDPOINT)
console.log('mode', process.env.NODE_ENV)

const Checkout = ({amount, event_id , event_name, cpd_hours}) => {
     const user = JSON.parse(localStorage.getItem("userData"))
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const navigate = useNavigate()
    const [cardNumber, setCardNumber] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [cvv, setCvv] = useState()
    const [isPaying, setIsPaying] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');



    
    const generateTransactionID = () => {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const idLength = 14;
      let id = "";
      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
      }
      const date = new Date().getTime().toString();
      console.log("generated id: ", id + date);
        const req_value = id + date
        return req_value
    };

    const bookEvent = () => {
        const url = `${process.env.REACT_APP_API_URL}api/v1/book-event`;
        const payload = {
          transaction_id: generateTransactionID(),
          event_id: event_id,
          user_id: user?.id,
          is_paid: 1,
        };
        console.log("payload before booking: ", payload)
        axios.post(url, payload)
        .then((response) => {
            console.log("response from booking", response?.data)    
            if (response?.data?.data?.status === 200) {
                setIsPaying(false);
                navigate("/success")
            }
        })
        .catch((error) => {
            setIsPaying(false);
            console.log("Error while booking: ", error)
            alert("Error while booking. Please try again")
           
        })
    }

    async function getResult(secret) {
        console.log("elements data: ", elements.getElement(CardElement))
        // const result = await stripe.confirmCardPayment(secret, {
        //     payment_method: {
        //         type: 'card',
        //         card: elements.getElement(CardElement),
        //         billing_details: {
        //             name: `${user?.first_name} ${user?.last_name}`,
        //             address: {
        //                 line1: "123 Street",
        //                 city: "City",
        //                 state: "State",
        //                 postal_code: "123456",
        //                 country: "IN"
        //             }
        //         },
        //     },
        // });


      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
      
      if (!cardNumberElement._complete){
        setErrorMessage('Please enter your card Number')
        setIsPaying(false)
        return
      } else if(!cardExpiryElement._complete){
        setErrorMessage("Please enter card's expiry date")
        setIsPaying(false)
        return
      }else if(!cardCvcElement._complete) {
        setErrorMessage("Please enter card's CVC")
        setIsPaying(false)
        return;
      }
  // First, call elements.submit() to handle any form validation.
  const {error: submitError} = await elements.submit();
  if (submitError) {
    console.log(submitError.message);
    setIsPaying(false)
    return;
  }
    const addressElement = elements.getElement(AddressElement);
    const addressData = await addressElement.getValue(); // Get address values
  
 
  const result = await stripe.confirmCardPayment(secret, {
    payment_method: {
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: addressData.value.name,
        address: {
          line1: addressData.value.address.line1,
          line2: addressData.value.address.line2 || '',
          city: addressData.value.address.city,
          state: addressData.value.address.state,
          postal_code: addressData.value.address.postal_code,
          country: addressData.value.address.country,
        },
      }
 
    }
  })
        if (result?.error) {
            // Handle payment failure
            setIsPaying(false);
            setErrorMessage(result?.error?.message || "Payment failed. Please try again.");
            console.error(result?.error,'errr');
        } else {
            console.log('payment success')
            // Payment successful
            bookEvent()
            cardNumberElement.clear()
            cardExpiryElement.clear()
            cardCvcElement.clear()
            addressElement.clear()
        }  
    }

    const stripeSubmit = async () => {
      setErrorMessage(null)
        setIsPaying(true);
        const payload = {
            amount: amount * 100,
            currency: 'gbp',
            description: `Booking for event ${event_name} (${cpd_hours} CPD Hours)`,
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_SECRET_KEY_API_ENDPOINT}`,
            payload,
            {withCredentials:true}
          )
          .then((response) => {
            console.log("response from initial post: ", response?.data);
            const data = response?.data;
            setClientSecret(data?.clientSecret);
            getResult(data?.clientSecret);
          })
          .catch((error) => {
            setIsPaying(false);
            console.log("Error from stripesubmit: ", error);
            setErrorMessage(error.message || "Payment failed. Please try again.");
          });     
    }



    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                iconColor: '#c4f0ff',
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "14px",
                "::placeholder": {
                    color: "#757680",
                },
                border: "1px solid #e0e0e0",  
                borderRadius: "4px",  
                padding: "10px" ,
            },
            
        
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };
    

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     // Create a payment intent on your server and get the client_secret
    //     const response = await fetch("https://assessment-portal-api-dev.thesketch.net/api/v1/create-payment-intent",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ amount: 850 }),
    //       }
    //     );
    //     const data = await response.json();
    //     setClientSecret(data.clientSecret);

    //     console.log("elements: ", elements.getElement(CardElement))

    //     // Confirm the card payment
    //     const result = await stripe.confirmCardPayment(data.clientSecret, {
    //         payment_method: {
    //             type: 'card',
    //             card: elements.getElement(CardElement),
    //             billing_details: {
    //                 name: 'John Doe',
    //             },
    //         },
    //     });

    //     if (result?.error) {
    //         // Handle payment failure
    //         console.error(result?.error);
    //     } else {
    //         // Payment successful
    //         navigate("/success");
    //     }
    // };
const handleFocus = (elementId)=> {
  const elementContainer = document.getElementById(elementId)
  elementContainer.style.borderColor = '#0570de'
  elementContainer.style.outline = 'solid'
  elementContainer.style.outlineWidth = '3.5px'
  elementContainer.style.outlineColor = '#C0DCF7'
}

const handleFocusLeft = (elementId)=> {
  const elementContainer = document.getElementById(elementId)
  elementContainer.style.borderColor = '#E6E6E6'
  elementContainer.style.outline = 'none'
}
const markRedOutline = (elementId)=> {
  const elementContainer = document.getElementById(elementId)
  elementContainer.style.outline = 'solid'
  elementContainer.style.outlineWidth = '3px'
  elementContainer.style.outlineColor = '#DE1C42'
}
useEffect(()=>{
  if(errorMessage==='Please enter your card Number'){
    markRedOutline('cardNumber')
  }
  if(errorMessage==="Please enter card's expiry date"){
    markRedOutline('expiry')
  }
  if(errorMessage==="Please enter card's CVC"){
    markRedOutline('cvc')
  }
},[errorMessage])

    return (
<div className='h-full font-pop flex justify-center items-center'>
            <form onSubmit={(e) => {
                e?.preventDefault()
                stripeSubmit()
            // }} className='p-4 w-full  flex flex-col gap-8 bg-white'>
            }} className='h-[400px] overflow-y-scroll  p-4 w-full  flex flex-col gap-2 bg-white'>
                {/* <div className=''>

                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <div>
                        <AddressElement options={{mode:'billing'}}/>
                       
                    </div>
                </div> */}
                
                <div className='space-y-2'>
                    <div>
                    <label className='text-[14.5px] text-[#30313d] font-sans'>Card number</label>
                    <div >
                        <CardNumberElement options={{showIcon:true}} id="cardNumber" onBlur={()=> handleFocusLeft('cardNumber')} onFocus={()=> handleFocus('cardNumber')} className="border rounded-md px-[10px] py-[12px]" />
                    </div>
                    </div>
              
                    <div className='flex w-full gap-5'>
                        <div className='basis-[50%]'>
                        <label className='text-[14.5px] text-[#30313d] font-sans'>Expiry date</label> 
                        <div > 
                          <CardExpiryElement id="expiry" onBlur={()=> handleFocusLeft('expiry')} onFocus={()=> handleFocus('expiry')}  className=' border rounded-md px-[10px] py-[12px] shadow-sm'  options={CARD_ELEMENT_OPTIONS} />
                          </div>
                        </div>

                        <div className='basis-[50%]'>
                        <label className='text-[14.5px] text-[#30313d] font-sans'>Security code</label> 
                        <div>  <CardCvcElement id="cvc" onBlur={()=> handleFocusLeft('cvc')} onFocus={()=> handleFocus('cvc')}  className='basis-[50%] border rounded-md px-[10px] py-[12px] shadow-sm ' options={CARD_ELEMENT_OPTIONS} />
                        </div>
                        </div>
                       
                        
                    </div>
                    <div>
                        <AddressElement options={{mode:'billing'}}/>
                    </div>
                </div>
                {/* <div className='w-full flexjustify-center mt-5 items-end'> */}
                <div className='w-full flex flex-col justify-center mt-5 items-center gap-1'>
                  { errorMessage && <p className='text-red-500 text-sm text-center'>{errorMessage}</p>}
                <button type="submit" className='bg-secondary shadow-sm text-white w-full py-2 rounded-md text-sm' disabled={!stripe}>
                        {isPaying
                            ? <svg
                                aria-hidden="true"
                                className="inline w-5 h-5 text-gray-100 animate-spin  fill-blue-400"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                    />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                    />
                              </svg>
                            : <p className='flex items-center justify-center'>Pay  
                            {/* <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 10h9.2M6 14h9.2M18 5a6 6 0 0 0-3.4-1C11 4 7.8 7.6 7.8 12s3 8 6.8 8a6 6 0 0 0 3.4-1"/>
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="text-white h-[18px] w-[18px]" viewBox="0 0 16 16">
                            <path d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.3 5.3 0 0 1 2.103.465V2.456A5.6 5.6 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4z"/>
                          </svg>
                           {amount}</p>}
                    </button>
                  
                </div>
            </form>
        </div>
    );
};

const CheckoutForm = ({fee, event_id, modal, updateShow, event_name, cpd_hours}) => {
    // const options = {
    //     currency: 'usd'
    // }
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem("userData"))
    // if(!user?.token){
    //     navigate('/login')
    //     return;
    //   }
    //   if(user?.role_id !== 2 ){
    //     navigate('/')
    //     return;
    //   }

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    },[])
     
    return (
        <dialog open={modal} className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'>
            <div className="w-full min-h-screen p-4 flex justify-center items-center">
                <div className='md:w-[1000px]  sm:w-[400px] shadow-md w-full overflow-hidden bg-white p-4 rounded-md max-h-[90vh] overflow-y-auto'>
                <div className='w-full flex justify-between items-center border-b border-gray-300 pb-2 '>
                    <p className='font-semibold text-lg text-primary'>Checkout</p>
                    <button type='button' onClick={()=>updateShow(false)} className='mb-1'>
                            <svg className="w-6 h-6 text-white bg-primary rounded-full p-1 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
                            </svg>
                        </button>
                </div>
             
                <div className='flex w-full justify-between '>
                <div className='w-[40%] flex flex-col gap-10 my-6'>
                   <div>
                    <div className='flex justify-between items-center'>
                    <p className=''>Course</p>
                    <p className=' text-lg '>{event_name}</p>
                    </div>

                  
                    <div className='flex justify-between items-center'>
                    <p className=''>CPD Hours</p>
                    <p className='text-lg'> {cpd_hours} hrs</p>
                    </div>
                    </div>
                    <div className='flex border-t pt-3 justify-between items-center'>
                    <p className=' text-lg font-medium'>Total</p>
                    <p className='text-lg text font-medium flex items-center '><svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="font-medium h-[18px] w-[18px]" viewBox="0 0 16 16">
  <path d="M4 8.585h1.969c.115.465.186.939.186 1.43 0 1.385-.736 2.496-2.075 2.771V14H12v-1.24H6.492v-.129c.825-.525 1.135-1.446 1.135-2.694 0-.465-.07-.913-.168-1.352h3.29v-.972H7.22c-.186-.723-.372-1.455-.372-2.247 0-1.274 1.047-2.066 2.58-2.066a5.3 5.3 0 0 1 2.103.465V2.456A5.6 5.6 0 0 0 9.348 2C6.865 2 5.322 3.291 5.322 5.366c0 .775.195 1.515.399 2.247H4z"/>
</svg>{fee}</p>
                    </div>
                </div>
                
                <div className='w-[57%] border-l my-2 px-2'>
                {!user?.token
                    ? <div className='px-5 text-center'>
                        <p className='text-center  text-xl my-8'>Please Login to book the course</p>
                        <button className='bg-secondary text-white py-2 px-40 rounded-md text-sm outline-none'
                            onClick={()=> navigate('/login')}>Login</button>
                        </div>
                    :
                 <Elements stripe={stripePromise}>
                        <Checkout   amount={fee} event_id={event_id}  event_name={event_name} cpd_hours={cpd_hours} />
                      </Elements>
                }
                </div>
                </div>
            </div>
            </div>
            
            
        </dialog>
        
    )
}

export default CheckoutForm;
