import React, { useEffect, useState } from "react";
import websiteLogo from '../assets/certificate-logo.png';
import cognismilesLogo from '../assets/cognismiles-logo.png'
 
const CTACards = [ //copy Array
  {id:1, question:'What did you learn in this course?'},
  {id:2, question:'How will you apply this course to your profession'},
  {id:3, question:'What did you learn in this course?'},
  {id:4, question:'What did you learn in this course?'},
]
 
 
const Certificate = React.forwardRef(({ module}, customRef) => {
 
const user = JSON.parse(localStorage.getItem('userData'))
const [courseDays, setCourseDays] = useState('')
 
//   return (
//     <>
//       {/* <div ref={customRef}> */}
//       <div ref={customRef} className="flex flex-col min-h-[1122px] w-[794px]" >
//         {/* Header */}
//         <div className=" bg-[#415DA1] py-12 flex justify-center  border-b-8 border-[#F87D6A]">
//             <img src={websiteLogo} className="scale-110" alt="Logo"/>
//         </div>
//         {/* Body */}
//         <div className="my-8 ml-24 mr-20 space-y-8">
//           <div className="flex items-center">
//             <p className="w-[180px]  flex justify-between mr-10 font-medium text-[15px]">Delegate Name <span>-</span></p>
//             <p className="font-bold text-lg ">{module?.first_name} {module?.last_name}</p>
//           </div>
//           <div className="flex items-center">
//             <p className="w-[180px]  flex justify-between mr-10 font-medium text-[15px]">GDC Number <span>-</span></p>
//             <p className="font-bold text-lg ">{user?.gdc_number}</p>
//           </div>
//           <div className="flex items-center">
//             <p className="w-[180px]  flex justify-between mr-10 font-medium text-[15px]">Course Name <span>-</span></p>
//             <p className="font-semibold text-lg">{module?.event_name}
//               <br/>
//               <span className="font-normal text-sm">Completed <span className="font-semibold">{module?.number_of_cpd_hours} hours</span> of CPD Hours</span>
//             </p>
//           </div>
//           <div className="flex items-center">
//             <p className="w-[180px]  flex justify-between mr-10 font-medium text-[15px]">Completion Date <span>-</span></p>
//             {/* <p className="font-bold ">{reqDateFormat(module?.event_end_date)}</p> */}
//             <p className="font-bold ">{module?.event_end_date}</p>
//           </div>
//           {/* Shadow Card */}
//           <div className="p-4 rounded-lg  text-center text-sm shadow-[0_0_5px_-1px_rgb(0,0,0,0.3)]">
//             <p className="font-bold">Meeting the following GDC Development Outcomes for enhanced CPD
//             </p>
//             <p className="font-semibold mt-1">C.D</p>
//           </div>
//           {/* Speakers */}
//           <div className="flex justify-center gap-12 text-sm border-t-2 border-gray-300 pt-10 pb-2">
//             <label className="shrink-0">Course Leads</label>
//             <div className="w-full flex justify-between">
//             {module?.speakers?.map(speaker=>
//                 <p key={speaker.id} className="font-semibold">{speaker.name}</p>
//               )}
//             </div>
//           </div>
//         </div>
//         {/* Footer */}
//          <div className="bg-[#E9F0FF] border-t-[3.5px] border-[#213870] pt-4 pb-12 flex">
//           <div className="w-1/3 grow px-5 text-sm border-r border-gray-400">
//             <h1 className="text-base font-medium text-[15px] mb-4">Aim</h1>
//             <p>{module?.aim}</p>
//           </div>
//           <div className="w-1/3 grow px-5 text-sm">
//             <h1 className="text-base font-medium mb-4">Objectives</h1>
//             <p>{module?.objectives}</p>
//           </div>
//           {module?.gdc_learning_outcomes &&
//             <div className="w-1/3 px-5  text-sm border-l border-gray-400">
//               <h1 className="text-base font-medium mb-4">Learning outcomes</h1>
//               <p>{module?.gdc_learning_outcomes}</p>
//             </div>
//             }
//         </div>
// {/* New Footer - for PDF view alignment */}
// {/* <div className="grow bg-[#E9F0FF] border-t-[3.5px] border-primary pt-4 pb-12">
//           <div className="px-5 text-sm">
//             <h1 className="text-base font-medium mb-2">Aim</h1>
//             <p>
//               {module?.aim}
//             </p>
//           </div>
//           <div className="px-5 mt-4 text-sm  ">
//             <h1 className="text-base font-medium mb-2 mt-3">Objectives</h1>
//             <p>
//               {module?.objectives}
//             </p>
//           </div>
//           {module?.gdc_learning_outcomes &&
//             <div className="px-5 mt-4 text-sm  break-inside-avoid ">
//               <h1 className="text-base font-medium mb-2 mt-3">Learning outcomes</h1>
//               <p>
//                 {module?.gdc_learning_outcomes}
//               </p>
//             </div>
//             }
//         </div> */}
// {/* END - New Footer */}
//       </div>
 
 
//   {/* 2nd Page */}
//       <div className="mt-12 pt-5 border-t-2 border-gray-300">
//         {/* Header */}
//         <div className=" bg-[#415DA1] py-12 flex justify-center  border-b-8 border-[#F87D6A]">
//             <img src={websiteLogo} className="scale-110" alt="Logo"/>
//         </div>
//         {/* Body */}
//         <div className="py-6 px-12 space-y-8">
//           {CTACards.map(card=> (
//             <div className="border border-gray-400 rounded-lg">
//               <div className="bg-[#415DA1] px-5 py-3 rounded-t-lg">
//                   <p className="text-white text-sm">What did you learn in this course?</p>
//               </div>
//               <div className="min-h-[120px] p-2">
                 
//               </div>
//             </div>
//           ))}
         
//         </div>
//         {/* Footer */}
//         <div className="bg-gray-100 min-h-[180px] flex flex-col justify-end">
//           <p className="border-t border-gray-400 text-center w-[180px] self-end pt-1 mr-12 mb-8 ">Signature</p>
//         </div>
//       </div>
//       </>
//     )

useEffect(()=> {
  const startDate = new Date(module.event_start_date)
  const endDate = new Date(module.event_end_date)
  const timeDifference = endDate - startDate
  const daysCount = Math.round(timeDifference/ (1000*60*60*24)) + 1
  setCourseDays(daysCount)
}, [])
 
return (
    <div ref={customRef} className="flex flex-col h-[1122px] w-[794px]" >
        {/* Header */}
        {/* <div className=" bg-[#415DA1] h-[20%] flex justify-center items-center border-b-8 border-[#F87D6A]">
            <img src={cognismilesLogo} className="w-[150px]" alt="Logo"/>
        </div> */}
       
        <div className="px-10">
        <img src={cognismilesLogo} className="w-[140px]" alt="Cognismiles"/>
        </div>
        <div className="text-center mt-5">
              <p className="text-2xl"> <span className="text-5xl font-medium">Certificate</span> <br/> of Continuous Professional Development <br/> ({module?.module_name})</p>
        </div>
         {/* Body */}
        <div className="flex justify-center items-center pt-20 pb-8">
          {/* <img src={watermarkLogo} className=" w-[58%] mx-auto opacity-10" alt="watermark_logo"/> */}
          {/* <div className="bg-watermark bg-contain bg-no-repeat bg-center bg-white/90"> */}
            <div className="text-center text-lg space-y-5 w-[75%]">
            
            <div>
              <p className="font-medium text-3xl">{module?.title} {module?.first_name} {module?.last_name}</p>
              <p>GDC no. {user?.gdc_number}</p>
            </div>
 
            <p>The above named has attended {courseDays} {courseDays > 1 ? "days" : 'day' } course on</p>
 
            <p className="font-medium text-3xl">{module?.event_name}</p>
 
            <div >
              <p className="text-lg">Days attended: {module?.event_start_date.split('-').reverse().join('-')} to {module?.event_end_date.split('-').reverse().join('-')}</p>
             
              <p className="text-base"> at <br/> {module?.event_place}, {module?.event_street}, {module?.event_city}, {module?.event_state}, {module?.event_country}.</p>
            </div>
 
            <p className="text-lg">Enhanced CPD hours: {module?.number_of_cpd_hours} hours</p>
 
            <div>
            <p className="text-lg">This course meets the GDC CPD Development Outcomes:</p>
            <p className="text-sm">{module?.gdc_learning_outcomes} </p>
            </div>
            </div>
          {/* </div> */}
        </div>
        {/* Footer */}
        <div className={`flex justify-between items-center w-[80%] mx-auto mt-16`}>
              {module?.speakers?.map((speaker, index)=>
                <div key={speaker.id}>
                  <p className="text-[13px]">Speaker {module?.speakers?.length>1 && `${index+1}`}</p>
                  <p>{speaker?.title} {speaker?.name}</p>
                  <p className="text-xs ">{"("}{speaker?.qualification}{")"}</p>
                </div>
              )}
          </div>
      </div>
)
});
 
export default Certificate;