import React, {useEffect, useState} from 'react'
import FileUploadForm from './FileUploadForm';
import axios from 'axios'
import { toast } from 'react-toastify';

const user = JSON.parse(localStorage.getItem("userData"));
const token = user?.token;
// const headers = {
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${token}`,"ngrok-skip-browser-warning": "skip-browser-warning",
// };

const FileTile = ({ file , fetchAllPatientFiles}) => {
  
  const [showFileOptions, setShowFileOptions] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  

  const handleDownload = () => {
     
    const downloadLink = file?.file_url;
    const anchor = document.createElement('a');
    anchor.href = downloadLink;
    anchor.download = 'downloaded_file_name'; 
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
      
  };

  const handleDelete = ()=> {
 
    const payload = {
      "file_id" : file.file_id,
    }
    const url = `${process.env.REACT_APP_API_URL}api/v1/deletefile`;
    console.log('payload before deleting', payload)
    setDeletePopup(false)
    axios.delete(url, {data:payload, withCredentials:true})
    .then(response=> {
      console.log(response)
      console.log("File Deleted Successfully!")
      fetchAllPatientFiles()
      toast.success('File Deleted Successfully!', {delay:500})
    })
    .catch(error=> {
      console.log("Error while Deleting file..")
      console.log(error)
    })
  }

  return (
    <div className="w-full flex flex-col justify-start items-start border border-gray-300 rounded-md p-2 gap-1">
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex items-start justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-xs text-gray-400">Uploaded By</p>
            <p className="text-sm font-medium">
              {file?.uploaded_by?.title +" "+file?.uploaded_by?.first_name +
                " " +
                file?.uploaded_by?.last_name}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xs font-medium text-gray-500">
              {file?.uploaded_at.split(' ')[0]}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-xs text-gray-400">File Type</p>
          <p className="text-sm font-medium">{file?.file_type}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs text-gray-400">Scan Taken Date</p>
          <p className="text-sm font-medium">{file?.scan_taken_date}</p>
        </div>
      </div>

      <div className='flex justify-end w-full items-center gap-5 p-1 '>
      <button
        title='Download File'
        className="p-2 text-gray-800 outline-0 border border-gray-800 rounded-md"
        onClick={handleDownload}
      >
        {/* <p className="text-xs font-medium">Download File</p> */}
     
          <svg
            className="w-4 h-4 text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 18"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
            />
          </svg>
        
      </button>
      <button
      title="Delete File" 
      className=" text-sm rounded-md p-[5px] border border-gray-800 font-medium"
        onClick={()=> setDeletePopup(true)}
      >
        <svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                  </svg>
 
      </button>
      </div>
      {/* Confirm delete Popup */}
      <dialog
        open={deletePopup}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-30 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className='bg-white flex flex-col p-8 w-full max-w-[400px] rounded-md shadow-md relative'>
              <p className="text-center text-base">Do you want to delete this file?</p>
              <div className="self-center space-x-4 mt-12">
                <button className="px-5 py-2 rounded-md text-sm bg-secondary text-white"
                  onClick={handleDelete}
                >Yes</button>
                <button className="px-5 py-2 rounded-md text-sm border border-secondary text-secondary"
                  onClick={()=> setDeletePopup(false)}
                >No</button>
              </div>
          </div>
        </div>
        </dialog>
    </div>
  );
};

const FileUploads = ({ patient_id, refreshPatientDetails , patient_code, type_of_referral, currentStatus }) => {
  const [filesData, setFilesData] = useState([]);
    const [showFileUploadForm, setShowFileUploadForm] = useState(false);
    
    const fetchAllPatientFiles = () => {
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/getfilesbypatientid`, { patient_id: patient_id }, {withCredentials:true})
        .then((response) => {
            console.log(`response from patientfiles fetch: `, response?.data?.data?.filedata)    
            setFilesData(response?.data?.data?.filedata);
        })
        .catch((error) => {
            console.log("error while fetching patient files: ", error)    
        })
    }

    useEffect(() => {
       fetchAllPatientFiles() 
    },[showFileUploadForm])

  return (
    <div className="max-h-[100vh] w-full flex flex-col gap-3">
      <div className="w-full flex justify-between items-start">
        <p>File Uploads</p>
        <button
          className="px-3 py-1 text-xl rounded-md bg-[#E64D26] text-white"
          onClick={() => setShowFileUploadForm(true)}
        >
          +
        </button>
      </div>
      {filesData?.length > 0 ? (<div className="overflow-y-auto w-full flex flex-col gap-4">
        {filesData?.map((file, index) => (
            <FileTile file={file} key={index} fetchAllPatientFiles={fetchAllPatientFiles} />
        ))}
          </div>) : (
                  <div className="w-full flex flex-col gap-4 justify-center items-center my-5">
                      <p>No files uploaded yet.</p>
                  </div>
      )}
      <dialog
        open={showFileUploadForm}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="md:w-[500px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-3">
            {/* <div className='w-full flex justify-end items-center'>
                <button className="border-2 border-gray-300 p-2 rounded-md" onClick={() => setShowPatientFormModal(false)}>❌</button>
              </div> */}
            <FileUploadForm
              showModal={setShowFileUploadForm}
              patient_id={patient_id}
              patient_code={patient_code}
              type_of_referral={type_of_referral}
              currentStatus={currentStatus}
              refreshPatientDetails={refreshPatientDetails}
            />
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default FileUploads